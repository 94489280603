<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Layout from "@/router/layouts/main";
import EventBus from "@/event-bus";
import { authComputed } from "@/state/helpers";
import Preloader from "@/components/preloader";
import ApplicationHeader from "@/components/applications/application-header"
import ApplicationPreview from "@/components/applications/application-preview";
import SidebarDetails from "@/components/applications/sidebar-details";
import SidebarAttachments from "@/components/applications/sidebar-attachments";
import Schedule from "@/components/applications/schedule";
import ScheduleApi from "@/components/applications/schedule-api";
import { roleService } from "@/app/service/roleService";
import { appService } from "@/app/service/appService";
import Repository from "@/app/repository/repository-factory";

const PaymentApplicationRepository = Repository.get("PaymentApplicationRepository");

export default {
  components: {
    Preloader,
    Layout,
    ApplicationHeader, ApplicationPreview,
    SidebarDetails, SidebarAttachments, Schedule, ScheduleApi
  },
  data() {
    return {
      preloader: false,
      requestErrorObj: null,
      disabledBtn: false,
      itemData: null,
      scheduleData: null,
      loggedUser: null,
      inputLoading: false,
      title: "Szczegóły wniosku",
      items: [
        {
          text: "Strona główna",
          to: {
            name: "home",
          },
        },
        {
          text: "Szczegóły wniosku",
          active: true,
        },
      ],
    };
  },
  created() {
    this.loggedUser = this.currentUser();
    if (this.$route.params.uuid) {
      this.createDetails(this.$route.params.uuid)
    }
    EventBus.$on("refreshAppDetailsPage", (id) => {
      this.createDetails(id);
    });
  },
  beforeDestroy() {
    EventBus.$off('refreshAppDetailsPage');
  },
  computed: {
    isAccepting() {
      return roleService.isAccepting();
    },
    isAcceptingCard() {
      return roleService.isAcceptingCard();
    },
    isApplicant() {
      return roleService.isApplicant();
    },
  },
  watch: {
    $route(to, from) {
      this.createDetails(this.$route.params.uuid)
    },
  },
  methods: {
    ...authComputed,
    async createDetails(id) {
      if (id) {
        this.preloader = true;
        try {
          const promise = await PaymentApplicationRepository.get(id);
          if (
            promise.data.typeOfApplication === 'Przelew skarbowy' &&
            promise.data.applicationSpecificData && promise.data.applicationSpecificData.cycleId
          )
          {
            try {
              const schedule = await PaymentApplicationRepository.getSchedule(id);
              await Promise.all([schedule]);
              this.scheduleData = schedule.data;
            } catch (error) {
              console.log(error);
            }
          }
          await Promise.all([promise]);
          this.itemData = promise.data;
          if (this.itemData.applicationSpecificData.prepaidCardSettlement) {
            let checkedCard = this.itemData.applicationSpecificData.prepaidCardSettlement.isChecked;
            if (!this.applicatntCanDiscardApp()) {
              this.disabledBtn = checkedCard ? false : true;
            }
          }
          this.preloader = false;
        } catch (error) {
          console.log(error);
          this.$router.push({
            name: "error-404",
          });
        }
      }
    },
    compareUserToAcceptor(user) {
      return user === this.$store.getters["auth/loggedIn"].userAccountId;
    },
    checkStatus(s, a) {
      return appService.checkAppStatus(s, a)
    },
    userCanCloneApp() {
      let permissions = this.$store.getters["auth/loggedIn"].permissions.filter((obj) => {
        return (
          obj.userAccountPermissionType === 1 &&
          obj.companyId === this.itemData.companyId
        );
      });
      return permissions.length > 0;
    },
    isNewAppForClone() {
      if (this.itemData.typeOfApplication == 'Przelew zagraniczny' || this.itemData.typeOfApplication == 'Przelew krajowy') {
        if ((this.itemData.changeDataManually === 0 && this.itemData.providerSapId != null) || this.itemData.changeDataManually === 1) {
          return true;
        }
      } else if (this.itemData.typeOfApplication == 'Zasilenie karty' || this.itemData.typeOfApplication == 'Przelew skarbowy') {
        return true;
      }
      return false;
    },
    userCanAcceptOrDiscardApp() {
      if (
        ((this.isAccepting && this.itemData.typeOfApplication != 'Zasilenie karty') || (this.isAcceptingCard && this.itemData.typeOfApplication == 'Zasilenie karty')) &&
        this.compareUserToAcceptor(this.itemData.acceptorUserAccountId) &&
          (this.itemData.changeDataManually === 0
              || (this.itemData.changeDataManually === 1
                  && (this.checkStatus(this.itemData.applicationStatus, [8]) || this.itemData.typeOfApplication === 'Przelew skarbowy'))
          )
      ) {
        return true;
      }
      return false;
    },
    userCanDiscardAppWhenDataChanged() {
      if (
        ((this.isAccepting && this.itemData.typeOfApplication != 'Zasilenie karty') || (this.isAcceptingCard && this.itemData.typeOfApplication == 'Zasilenie karty')) &&
        this.compareUserToAcceptor(this.itemData.acceptorUserAccountId)
      ) {
        return true;
      }
      return false;
    },
    applicatntCanDiscardApp() {
      if (
        this.isApplicant &&
        this.compareUserToAcceptor(this.itemData.applicantUserAccountId) &&
        this.checkStatus(this.itemData.applicationStatus, [1, 3, 8])
      ) {
        return true;
      }
      return false
    },
    userCanChangeAcceptor() {
      if (
        this.compareUserToAcceptor(this.itemData.applicantUserAccountId) &&
        this.checkStatus(this.itemData.applicationStatus, [1])
      ) {
        return true;
      }
      return false;
    },
    checkPrepaidCard() {
      this.inputLoading = true;
      this.requestErrorObj = null;
      let payload = {
        version: this.itemData.version,
      };
      PaymentApplicationRepository.checkAndSave(payload, this.$route.params.uuid)
        .then((response) => {
          this.inputLoading = false;
          if (this.$route.params.uuid) {
            this.createDetails(this.$route.params.uuid)
          }
        })
        .catch((error) => {
          this.inputLoading = false;
          this.requestErrorObj = error;
          console.log(error);
        });
    },
    cloneApp(data) {
      localStorage.setItem("cloneApplicationAction", JSON.stringify(data));
      switch (data.typeOfApplication) {
      case "Przelew krajowy":
        this.$router.push({ name: "Przelew krajowy" });
        break;
      case "Przelew zagraniczny":
        this.$router.push({ name: "Przelew zagraniczny" });
        break;
      case "Przelew skarbowy":
        this.$router.push({ name: "Przelew skarbowy" });
        break;
      case "Zasilenie karty":
        this.$router.push({ name: "Zasilenie karty" });
        break;
      default:
        break;
      }
    },
    sendApp(type) {
      switch (type) {
      case "accept":
        this._status = 3;
        this._text = "Wniosek został zaakceptowany!";
        this._hasReason = false;
        this._textReason = "";
        this._textCancel = "";
        this._textBtn = "";
        break;
      case "discardByAcceptor":
        this._status = 2;
        this._text = "Wniosek został odrzucony!";
        this._hasReason = true;
        this._textReason = "Podaj przyczynę odrzucenia wniosku";
        this._textCancel = "Wniosek nie został odrzucony!";
        this._textBtn = "Odrzuć wniosek";
        break;
      case "discardByApplicant":
        this._status = 10;
        this._text = "Wniosek został odrzucony!";
        this._hasReason = true;
        this._textReason = "Podaj przyczynę odrzucenia wniosku";
        this._textCancel = "Wniosek nie został odrzucony!";
        this._textBtn = "Odrzuć wniosek";
        break;
      default:
        break;
      }
      this.sendApplication(
        this._status,
        this._text,
        this._hasReason,
        this._textReason,
        this._textCancel,
        this._textBtn
      );
    },
    sendApplication(
      _status,
      _text,
      _hasReason,
      _textReason,
      _textCancel,
      _textBtn
    ) {
      if (!_hasReason) {
        let payload = {
          applicationStatus: _status,
          version: this.itemData.version,
        };
        this.apiGo(payload, _text);
      } else {
        Swal.fire({
          title: _textReason,
          input: "text",
          showCancelButton: true,
          confirmButtonText: _textBtn,
          cancelButtonText: "Anuluj",
          showLoaderOnConfirm: true,
          confirmButtonColor: "#61aa3d",
          cancelButtonColor: "#e2001a",
          preConfirm: (reason) => {
            let payload = {
              applicationStatus: _status,
              statusMessage: reason,
              version: this.itemData.version,
            };
            this.apiGo(payload, _text);
          },
          allowOutsideClick: false,
        }).then((reason) => {
          if (reason.value) {
            "SUKCES!", _text, "success";
          } else if (reason.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Anulowano", _textCancel, "error");
            this.disabledBtn = false;
            this.preloader = false;
          }
        });
      }
    },
    apiGo(payload, _text) {
      this.disabledBtn = true;
      this.preloader = true;
      PaymentApplicationRepository.changeStatus(
        payload,
        this.$route.params.uuid
      )
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES! ",
            text: _text,
            showConfirmButton: false,
            timer: 2000,
            onClose: () => {
              this.$router.push({
                name: "home",
              });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />

    <div class="row align-items-center" v-if="itemData">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ title }}</h4>
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <div class="float-right d-none d-md-block" v-if="userCanChangeAcceptor() || userCanCloneApp()">
            <b-link class="btn btn-info mr-2"
              v-if="userCanChangeAcceptor()"
              :to="{ name: 'acceptor-change', params: { id: this.$route.params.id } }"
            >
              <i class="fas fa-exchange-alt font-size-12 mr-2"></i>Zmiana akceptującego
            </b-link>
            <b-link class="btn btn-success"
              v-if="userCanCloneApp() && isNewAppForClone()"
              v-on:click="cloneApp(itemData)"
              :to="{}"
            >
              <i class="mdi mdi-content-copy font-size-12 mr-2"></i>Ponowienie wniosku
            </b-link>
        </div>
      </div>
    </div>

    <div class="row" v-if="itemData && (isAccepting || isAcceptingCard || isApplicant)">
      <div class="col-md-8">
        <div class="card shadow">
          <div class="card-body">
            <ApplicationHeader :itemData="itemData" />
            <hr />
            <b-alert variant="warning" class="mb-3" show fade v-if="this.itemData.changeDataManually === 1">
              <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Podczas składania wniosku zmodyfikowano ręcznie dane dostawcy!
            </b-alert>
            <b-alert variant="warning" class="mb-3" show fade v-if="this.itemData.applicationSpecificData.prepaidCardSettlement && this.itemData.applicationSpecificData.prepaidCardSettlement.isChecked === false">
              <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Podczas składania wniosku o zasilenie, nie sprawdzono rozliczenia karty!
            </b-alert>
            <b-alert variant="info" class="mb-3" show fade v-if="this.itemData.changeDataManually === 1 && checkStatus(this.itemData.applicationStatus, [1]) && this.itemData.higherAcceptorUserAccountId != null">
              <i class="mdi mdi-information-outline mr-2"></i><strong>Uwaga!</strong> Wniosek oczekuje na autoryzację akceptującego wyższego rzędu!
            </b-alert>
            <!-- <b-alert variant="info" class="mb-3" show fade v-if="this.itemData.acceptorWasChanged === 1">
              <i class="mdi mdi-information-outline mr-2"></i><strong>Uwaga!</strong> Zmieniono akceptującego wniosek!
            </b-alert> -->
            <!--
            <button class="btn btn-success btn-block my-3"
              v-if="this.itemData.applicationSpecificData.prepaidCardSettlement && userCanAcceptOrDiscardApp() && checkStatus(this.itemData.applicationStatus, [1, 3, 8])"
              @click="checkPrepaidCard"
              :disabled="inputLoading">
              <i v-if="inputLoading" class="el-icon-loading mr-1"></i>
              <i v-else class="el-icon-circle-check mr-1"></i>
              Sprawdź rozliczenie karty
            </button>
            -->
            <b-alert variant="danger" class="mb-3" show fade v-if="requestErrorObj">
              <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Wystąpił błąd!
              <br /><small>{{ requestErrorObj.response.data.message }}</small>
            </b-alert>
            <ApplicationPreview :itemData="itemData" />
            <div class="row">
              <div class="col" v-if="userCanAcceptOrDiscardApp() && checkStatus(this.itemData.applicationStatus, [1, 8])">
                <b-button block :disabled="disabledBtn" variant="success" v-on:click="sendApp('accept')">Zaakceptuj wniosek</b-button>
              </div>
              <div class="col text-right">
                <b-button :disabled="disabledBtn" variant="danger" v-on:click="sendApp('discardByAcceptor')" v-if="userCanDiscardAppWhenDataChanged() && checkStatus(itemData.applicationStatus, [1, 3, 8])">
                  Odrzuć wniosek
                </b-button>
                <b-button :disabled="disabledBtn" variant="danger" v-on:click="sendApp('discardByApplicant')" v-if="applicatntCanDiscardApp()">
                  Odrzuć wniosek
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-0">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-playlist-star"></i>
                </span>
                <span class="d-none d-sm-inline-block">Dane wniosku</span>
              </template>
              <SidebarDetails :itemData="itemData" />
            </b-tab>
            <b-tab v-if="this.itemData.attachments && this.itemData.attachments.length > 0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-attachment"></i>
                </span>
                <el-badge :value="itemData.attachments.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Załączniki</span>
                </el-badge>
              </template>
              <SidebarAttachments :itemData="itemData" />
            </b-tab>
            <b-tab v-if="this.itemData.applicationSpecificData.isRecurring && (itemData.applicationSpecificData.schedule.length > 0 || scheduleData)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-clock-check-outline"></i>
                </span>
                <el-badge :value="itemData.applicationSpecificData.schedule.length || scheduleData.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Harmonogram</span>
                </el-badge>
              </template>
              <Schedule :itemData="itemData" v-if="!scheduleData && this.itemData.applicationSpecificData.isRecurring && this.itemData.applicationSpecificData.schedule.length > 0" />
              <ScheduleApi :itemData="itemData" :scheduleData="scheduleData" v-else-if="scheduleData"/>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>