<script>
import { appService } from "@/app/service/appService";
import { typeOfTaxPeriods, typeOfTaxSettlement } from "@/data/data-type-of-tax-periods";

/**
* Schedule
*/
export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      transferPeriod: typeOfTaxPeriods,
      transferPeriodDay: typeOfTaxSettlement,
      currencyFilter: null,
    };
  },
  methods: {
    checkColor(s) {
      return appService.checkAppColor(s)
    },
    displayTextPeriod(symbol) {
      const text = this.transferPeriod.filter((obj) => {
        return obj.symbol === symbol;
      });
      return text[0].name;
    },
    displayTextPeriodDay(symbol) {
      const text = this.transferPeriodDay.filter((obj) => {
        return obj.symbol === symbol;
      });
      return text[0].name;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header">
      <strong><i class="mdi mdi-calendar-clock mr-2"></i>Harmonogram</strong> / Cykl : {{ this.itemData.applicationSpecificData.period.type }} - {{ displayTextPeriod(this.itemData.applicationSpecificData.period.type) }}
    </div>
    <div class="table-responsive mb-0">
      <div data-simplebar class="conversation-list px-0">
        <div class="simplebar-scroll-content">
          <div class="simplebar-content">
            <table class="table table-sm mb-0">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Nr</th>
                  <th>Data</th>
                  <th>Dzień</th>
                  <th>Kwota</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sData, index) in itemData.applicationSpecificData.schedule" :key="index" :class="sData.applicationId === $route.params.uuid ? 'bg-secondary' : ''">
                  <td>&nbsp;</td>
                  <td class="align-middle" scope="row">
                    <span v-b-tooltip.hover.right :title="itemData.applicationStatus" style="cursor:help">
                      <span :class="`badge badge-pill font-size-10 badge-soft-${checkColor(itemData.applicationStatus)}`">{{ index + 1 }}</span>
                    </span>
                  </td>
                  <td class="align-middle" :class="itemData.applicationStatus == 'Zrealizowane' ? 'font-weight-bold' : ''">
                    {{ sData }}
                  </td>
                  <td class="align-middle" :class="itemData.applicationStatus == 'Zrealizowane' ? 'font-weight-bold' : ''">{{ new Date(sData).toLocaleString('pl-PL', {weekday:'long'}) }}</td>
                  <td :class="itemData.applicationStatus == 'Zrealizowane' ? 'font-weight-bold' : ''">{{ itemData.grossAmount | currencyFilter }} PLN</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <ul class="list-group list-group-flush border-top">
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <span><i class="mdi mdi-transfer mr-2"></i>Wszystkich przelewów</span> <span>{{ this.itemData.applicationSpecificData.schedule.length }}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <span><i class="mdi mdi-coin-outline mr-2"></i>Łączna kwota</span> <span>{{ (this.itemData.applicationSpecificData.schedule.length * this.itemData.grossAmount) | currencyFilter }} PLN</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <span><i class="mdi mdi-timelapse mr-2"></i>W okresie</span> <span>{{ this.itemData.transferDateOfOperationField | formattedDate }} - {{ this.itemData.applicationSpecificData.endDateOfTheCycle | formattedDate }}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <span><i class="mdi mdi-bank-transfer mr-2"></i>Zlecenie przelewu</span> <span>{{ displayTextPeriodDay(this.itemData.applicationSpecificData.period.cycleGenerationDay) }}</span>
      </li>
    </ul>
  </div>
</template>