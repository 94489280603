<script>
import { appService } from "@/app/service/appService";

/**
 * Application preview
 */
export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currencyFilter: null,
      formatAccNo: null,
      formatPrepaidCardNo: null,
    };
  },
  methods: {
    checkStatus(s, a) {
      return appService.checkAppStatus(s, a)
    },
  },
};
</script>

<template>
  <div>
    <b-alert variant="danger" class="mb-3" show fade v-if="checkStatus(itemData.applicationStatus, [2, 4, 6, 9, 10, 11, 12])">
      <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Wniosek został odrzucony lub jest przedawniony!
      <template v-if="itemData.statusMessage">
        <br />Przyczyna: <em>{{ itemData.statusMessage }}</em>
      </template>
    </b-alert>
    <div class="row px-2 py-3 mx-0 mb-4" style="border: 1px solid #ccc">
      <div class="col-12 col-md-6 mb-4 mb-md-0">
        <address>
          <h5 class="mb-1">Dane firmy</h5>
          <br />
          <strong class="mr-1">Nazwa:</strong>
          {{ itemData.companyName }}
          <br />
          <strong class="mr-1">Adres:</strong>
          {{ itemData.companyAddress }}
          <br />
          <strong class="mr-1">NIP:</strong>
          <span>{{ itemData.companyTaxNumber }}</span>
          <br />
          <strong class="mr-1">Rachunek:</strong>
          {{ itemData.bankAccountNumber | formatAccNo }}
          <br />
          <template v-if="itemData.typeOfApplication == 'Przelew zagraniczny'">
            <strong class="mr-1">Bank:</strong>
            <span>{{ itemData.bankName  }}</span>
            <br />
          </template>
        </address>
      </div>
      <div class="col-12 col-md-6 text-md-right">
        <address>
          <template v-if="itemData.typeOfApplication == 'Zasilenie karty'">
            <h5 class="mb-1">Dane karty</h5>
            <br />
            <strong class="mr-1">Nr karty:</strong>
            {{ itemData.applicationSpecificData.prepaidCardNumber | formatPrepaidCardNo }}
            <br />
            <strong class="mr-1">Konto:</strong>
            {{ itemData.providerBankAccountNumber | formatAccNo }}
            <br />
            <strong class="mr-1">Właściciel:</strong>
            {{ itemData.providerName }}
            <br />
            <strong class="mr-1">Identyfikator użytkownika karty:</strong>
            {{ itemData.providerTaxNumber }}
            <br />
          </template>
          <template v-else>
            <h5 class="mb-1">
              <template v-if="itemData.typeOfApplication == 'Zasilenie karty'">Dane karty</template>
              <template v-else-if="itemData.typeOfApplication == 'Przelew skarbowy'">Organ podatkowy</template>
              <template v-else>Dane dostawcy</template>
            </h5>
            <br />
            <strong class="mr-1">Nazwa:</strong>
            {{ itemData.providerName }}
            <br />
            <strong class="mr-1">Adres:</strong>
            {{ itemData.providerAddress }}
            <br />
            <template v-if="itemData.typeOfApplication != 'Przelew skarbowy' && itemData.providerTaxNumber">
            <strong class="mr-1">NIP:</strong>
            {{ itemData.providerTaxNumber }}
            <br />
            </template>
            <strong class="mr-1">Rachunek:</strong>
            {{ itemData.applicationSpecificData.providerBankCountry }} {{ itemData.providerBankAccountNumber | formatAccNo }}
            <br />
          </template>
          <template v-if="itemData.typeOfApplication == 'Przelew zagraniczny'">
            <strong class="mr-1">Bank:</strong>
            <span>{{ itemData.applicationSpecificData.providerBankName }}</span>
            <br />
            <strong class="mr-1">BIC / SWIFT:</strong>
            <span>{{ itemData.applicationSpecificData.providerSwift }}</span>
            <br/>
          </template>
        </address>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <p class="lead my-4 text-center">
              <strong>Tytuł przelewu</strong>
              <br />
              {{ itemData.transfer }}
            </p>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="bg-soft-secondary">
                  <tr>
                    <th>Data operacji</th>
                    <th v-if="itemData.typeOfApplication == 'Przelew krajowy' && itemData.typeOfTransfer == 'Split payment'">Numer FV</th>
                    <th>Rodzaj rachunku</th>
                    <th class="text-right">Kwota przelewu {{ itemData.typeOfApplication == 'Przelew skarbowy' ? '' : 'brutto' }}</th>
                    <th class="text-right" v-if="itemData.typeOfApplication == 'Przelew krajowy' && itemData.typeOfTransfer == 'Split payment'">Kwota vat</th>
                    <th>Waluta</th>
                    <th v-if="itemData.typeOfApplication == 'Przelew skarbowy'">Symbol</th>
                    <th v-if="itemData.typeOfApplication == 'Przelew skarbowy'">Typ</th>
                    <th v-if="itemData.typeOfApplication == 'Przelew skarbowy'">Kod</th>
                    <th v-if="itemData.typeOfApplication == 'Przelew skarbowy' && itemData.applicationSpecificData.endDateOfTheCycle">Zakończenie cyklu</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ itemData.dateOfOperation | formattedDate }}</td>
                    <td v-if="itemData.typeOfApplication == 'Przelew krajowy' && itemData.typeOfTransfer == 'Split payment'">{{ itemData.applicationSpecificData.invoiceNumber }}</td>
                    <td>{{ itemData.typeOfBankAccount }}</td>
                    <td class="text-right">{{ itemData.grossAmount | currencyFilter }}</td>
                    <td class="text-right" v-if="itemData.typeOfApplication == 'Przelew krajowy' && itemData.typeOfTransfer == 'Split payment'">{{ itemData.applicationSpecificData.vatAmount | currencyFilter }}</td>
                    <td>{{ itemData.currency }}</td>
                    <td v-if="itemData.typeOfApplication == 'Przelew skarbowy'">{{ itemData.applicationSpecificData.taxFormSymbol }}</td>
                    <td v-if="itemData.typeOfApplication == 'Przelew skarbowy'">{{ itemData.applicationSpecificData.isRecurring ? "Cykliczny" : "Jednorazowy" }}</td>
                    <td v-if="itemData.typeOfApplication == 'Przelew skarbowy'">{{ itemData.applicationSpecificData.period ? itemData.applicationSpecificData.period.shortCode : ""}}</td>
                    <td v-if="itemData.typeOfApplication == 'Przelew skarbowy' && itemData.applicationSpecificData.endDateOfTheCycle">{{ itemData.applicationSpecificData.endDateOfTheCycle | formattedDate }}</td>
                  </tr>
                </tbody>
              </table>
              <hr class="p-0 m-0" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <b-alert variant="info" class="mb-3" show fade v-if="itemData.confirmTransfer || itemData.providerCheckedWhiteList">
          <template v-if="itemData.confirmTransfer"><i class="mdi mdi-information-outline mr-2"></i>Wnioskujący zlecił dołączenie potwierdzenia wykonania przelewu.<br v-if="itemData.providerCheckedWhiteList" /></template>
          <template v-if="itemData.providerCheckedWhiteList"><i class="mdi mdi-information-outline mr-2"></i>Dostawca został sprawdzony na białej liście.</template>
        </b-alert>
        <b-alert variant="success" class="mb-3" show fade v-if="itemData.typeOfApplication == 'Przelew zagraniczny'">
          <i class="mdi mdi-information-outline mr-2"></i>Prowizje i opłaty po stronie <strong>{{ itemData.applicationSpecificData.feesAndCharges }}</strong>.<br />
          <i class="mdi mdi-information-outline mr-2"></i><strong>{{ itemData.applicationSpecificData.taxOpinion ? "Podlega" : "Nie podlega" }}</strong> opodatkowaniu.
        </b-alert>

        <b-alert variant="success" class="mb-3" show fade v-if="itemData.typeOfApplication == 'Zasilenie karty' && itemData.applicationSpecificData.prepaidCardSettlement">
          <i class="mdi mdi-information-outline mr-2"></i>Karta <strong>{{ itemData.applicationSpecificData.prepaidCardSettlement.isChecked ? "została" : "nie została" }}</strong> sprawdzona.<br />
          <template v-if="itemData.applicationSpecificData.prepaidCardSettlement.isSettled">
          <i class="mdi mdi-information-outline mr-2"></i>Karta <strong>jest</strong> rozliczona.<br />
          </template>
          <template v-else>
            <template v-if="itemData.applicationSpecificData.prepaidCardSettlement.balance != null">
            <i class="mdi mdi-information-outline mr-2"></i>Na karcie istnieją nierozliczone transakcje na kwotę {{ itemData.applicationSpecificData.prepaidCardSettlement.balance }}
            </template>
            <template v-else>
            <i class="mdi mdi-information-outline mr-2"></i>Na karcie istnieją nierozliczone transakcje. Brak informacji o wysokości nierozliczonej kwoty.
            </template>
          </template>
        </b-alert>
        <template v-if="itemData.applicationSpecificData.prepaidCardSettlement">
          <b-alert variant="danger" class="mb-3" show fade v-if="!itemData.applicationSpecificData.prepaidCardSettlement.isSettled">
            <i class="mdi mdi-shield-alert-outline mr-2"></i>Karta <strong>nie jest</strong> rozliczona.
          </b-alert>
        </template>

        <!-- <div class="table-responsive" v-if="itemData.applicationSpecificData.prepaidCardSettlement && itemData.applicationSpecificData.prepaidCardSettlement.transactions">
          <div data-simplebar class="conversation-list px-0">
            <div class="simplebar-scroll-content">
              <div class="simplebar-content">
                <table class="table table-sm mb-0">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Nr</th>
                      <th>Tytuł</th>
                      <th>Data</th>
                      <th>Kwota</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sData, index) in itemData.applicationSpecificData.prepaidCardSettlement.transactions" :key="index">
                      <td>&nbsp;</td>
                      <td class="align-middle" scope="row">
                        {{ index + 1 }}
                      </td>
                      <td class="align-middle">
                        {{ sData.name }}
                      </td>
                      <td class="align-middle">{{ sData.date }}</td>
                      <td>{{ sData.amount }} PLN</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> -->

        <blockquote class="blockquote" v-if="itemData.additionalInfo">
          <p class="mb-0 small">{{ itemData.additionalInfo }}</p>
          <footer class="blockquote-footer text-muted" style="font-size:75%">Dodatkowe uwagi od <cite title="Source Title">{{ itemData.applicantName }}</cite></footer>
        </blockquote>
      </div>
    </div>
  </div>
</template>