<script>
/**
* Application preview
*/
export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    collapse: {
      type: String,
      required: false,
    },
    backUrl: {
      type: Object,
      required: false,
      default: function() {
        return null
      },
    },
  },
  data() {
    return {
    };
  },
};
</script>

<template>
  <div class="row align-items-center">
    <div class="col-md-8">
      <div class="media">
        <img class="mr-3 mt-1" src="@/assets/images/si-pay-logo.png" alt="logo" height="30" />
        <div class="media-body">
          <h5 class="my-0">
            Akceptujący : {{ itemData.acceptorName }}
          </h5>
          <p class="small m-0 p-0">{{ itemData.acceptorEmail }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-right">
      <b-link v-if="backUrl" class="btn btn-sm btn-warning" :to="backUrl"><i class="mdi mdi-chevron-left mr-2"></i>Powrót</b-link>
      <button v-else class="btn btn-sm btn-warning" @click="$router.go(-1)"><i class="mdi mdi-chevron-left mr-2"></i>Powrót</button>
      <b-button v-b-toggle="collapse" class="btn-sm ml-2" variant="primary" v-if="collapse">
        <span class="when-opened"><i aria-hidden="true" class="mdi mdi-chevron-up"></i></span>
        <span class="when-closed"><i aria-hidden="true" class="mdi mdi-chevron-down"></i></span>
      </b-button>
    </div>
  </div>
</template>