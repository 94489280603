<script>
import { appService } from "@/app/service/appService";

/**
 * Sidebar details
 */
export default {
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currencyFilter: null,
      formatAccNo: null,
    };
  },
  methods: {
    checkColor(s) {
      return appService.checkAppColor(s);
    },
  },
};
</script>

<template>
  <div class="card-body">
    <h5 class="m-0 p-0">{{ itemData.applicationNumber }}</h5>
    <small class="text-muted">Utworzono: {{ itemData.createdAt }}</small><br />
    <span class="mt-2 badge text-wrap font-size-14" :class="`badge-soft-${checkColor(itemData.applicationStatus)}`">{{ itemData.applicationStatus }}</span>
    <hr />
    <template v-if="itemData.acceptorDate || itemData.higherAcceptorDate">
      <small class="text-muted" v-if="itemData.acceptorDate">
        <i class="fas fa-clock mr-1 text-warning"></i><strong>Akceptacja:</strong>
        <span class="float-right">{{ itemData.acceptorDate }}</span>
      </small><br />
      <small class="text-muted" v-if="itemData.higherAcceptorDate">
        <i class="fas fa-clock mr-1 text-info"></i><strong>Autoryzacja:</strong>
        <span class="float-right">{{ itemData.higherAcceptorDate }}</span>
      </small>
      <hr />
    </template>
    <h5>Wnioskujący</h5>
    <div class="media d-flex align-items-center">
      <div class="avatar-xs mr-2">
        <b-avatar variant="success" :text="itemData.applicantName | nameLetters"></b-avatar>
      </div>
      <div class="media-body">
        <p class="m-0 p-0">{{ itemData.applicantName }}</p>
        <small><a class="text-muted" :href="'mailto:' + itemData.applicantEmail + '?subject=' + itemData.applicationNumber">{{ itemData.applicantEmail }}</a></small>
      </div>
    </div>
    <hr />
    <h5>Akceptujący</h5>
    <div class="media d-flex align-items-center">
      <div class="avatar-xs mr-2">
        <b-avatar variant="warning" :text="itemData.acceptorName | nameLetters"></b-avatar>
      </div>
      <div class="media-body">
        <p class="m-0 p-0">{{ itemData.acceptorName }}</p>
        <small><a class="text-muted" :href="'mailto:' + itemData.acceptorEmail + '?subject=' + itemData.applicationNumber">{{ itemData.acceptorEmail }}</a></small>
      </div>
    </div>
    <hr />
    <template v-if="itemData.changeDataManually == 1 && itemData.higherAcceptorEmail">
      <h5>Autoryzujący zmiany dostawcy</h5>
      <div class="media d-flex align-items-center">
        <div class="avatar-xs mr-2">
          <b-avatar variant="info" :text="itemData.higherAcceptorName | nameLetters"></b-avatar>
        </div>
        <div class="media-body">
          <p class="m-0 p-0">{{ itemData.higherAcceptorName }}</p>
          <small><a class="text-muted" :href="'mailto:' + itemData.higherAcceptorEmail + '?subject=' + itemData.applicationNumber">{{ itemData.higherAcceptorEmail }}</a></small>
        </div>
      </div>
      <hr />
    </template>
    <h5 class="m-0 p-0">Dane wniosku</h5>
    <br />
    <strong class="mr-1">Rodzaj:</strong>
    <span>{{ itemData.typeOfApplication }}</span>
    <br />
    <template v-if="itemData.typeOfApplication == 'Przelew skarbowy'">
      <strong class="mr-1">Podatek:</strong>
      <span>{{ itemData.applicationSpecificData.taxGroupSymbol }}</span>
      <br />
      <strong class="mr-1">Formularz:</strong>
      <span>{{ itemData.applicationSpecificData.taxFormSymbol }}</span>
      <br />
      <strong class="mr-1">Kod:</strong>
      <span>{{ itemData.applicationSpecificData.period ? itemData.applicationSpecificData.period.shortCode : ""}}</span>
      <br />
    </template>
    <strong class="mr-1">Numer:</strong>
    <span>{{ itemData.applicationNumber }}</span>
    <br />
    <template v-if="itemData.typeOfApplication == 'Przelew krajowy'">
      <strong class="mr-1">Typ:</strong>
      <span>{{ itemData.typeOfTransfer }}</span>
      <br />
    </template>
    <template v-if="itemData.typeOfApplication == 'Przelew skarbowy'">
      <strong class="mr-1">Typ:</strong>
      <span>{{ itemData.applicationSpecificData.isRecurring ? 'Cykliczny' : 'Jednorazowy' }}</span>
      <br />
    </template>
    <strong class="mr-1">Data {{ itemData.applicationSpecificData.isRecurring ? 'pierwszej' : '' }} operacji:</strong>
    <span>{{ itemData.dateOfOperation }}</span>
    <br />
    <template v-if="itemData.applicationSpecificData.isRecurring">
      <strong class="mr-1">Data zakończenia cyklu:</strong>
      <span>{{ itemData.applicationSpecificData.endDateOfTheCycle }}</span>
      <br />
    </template>
    <br />
    <strong class="mr-1">Nazwa banku:</strong>
    <span>{{ itemData.bankName }}</span>
    <br />
    <strong class="mr-1">Skrót banku:</strong>
    <span>{{ itemData.bankShortName }}</span>
    <br />
    <strong class="mr-1">Rodzaj rachunku:</strong>
    <span>{{ itemData.typeOfBankAccount }}</span>
    <br />
    <strong class="mr-1">Rachunek:</strong>
    <span>{{ itemData.bankAccountNumber | formatAccNo }}</span>
    <br />
    <strong class="mr-1">Kwota przelewu {{ itemData.typeOfApplication == 'Przelew skarbowy' ? '' : ' brutto'}}:</strong>
    <span>{{ itemData.grossAmount | currencyFilter }}</span>
    <template v-if="itemData.typeOfApplication == 'Przelew krajowy' && itemData.typeOfTransfer == 'Split payment'">
      <br />
      <strong class="mr-1">Kwota vat:</strong>
      <span>{{ itemData.applicationSpecificData.vatAmount | currencyFilter }}</span>
      <br />
      <strong class="mr-1">Numer faktury Vat:</strong>
      <span>{{ itemData.applicationSpecificData.invoiceNumber }}</span>
    </template>
    <br />
    <strong class="mr-1">Waluta:</strong>
    <span>{{ itemData.currency }}</span>
  </div>
</template>