import { typeOfApplicationStatus } from "@/data/data-type-of-application-status";
import moment from "moment";

export const appService = {
  getTimeClock,
  getFormattedDate,
  checkAppStatus,
  checkAppColor,
  getPeriodTypeNumber,
};

function getTimeClock() {
  return moment().locale("pl").format("dddd D MMMM YYYY, H:mm:ss")
}

function getFormattedDate(date, format) {
  let d = date != null ? date : new Date();
  return moment(d).format(format);
}

// checks if appliation has status defined in array
function checkAppStatus(status, StatsArr) {
  for (const item of StatsArr) {
    for (const stat of typeOfApplicationStatus) {
      if (stat.index === item && stat.name === status) {
        return true;
      }
    }
  }
}

// checks if application has defined color
function checkAppColor(status) {
  for (const stat of typeOfApplicationStatus) {
    if (stat.name === status) {
      return stat.color;
    }
  }
  return 'primary'
}

function getPeriodTypeNumber(data) {
  if (data) {
    let periodNum = null;
    const periodType = data.transferPeriodTypeValueField ? data.transferPeriodTypeValueField.symbol : null;
    const _yearDate = data.transferSettleYearField ? data.transferSettleYearField : moment(new Date());
    const _halfyearSymbol = data.transferPeriodSettleHalfYearValueField ? data.transferPeriodSettleHalfYearValueField.symbol : "";
    const _quarterSymbol = data.transferPeriodSettleQuarterValueField ? data.transferPeriodSettleQuarterValueField.symbol : "";
    const _monthDate = data.transferPeriodSettleMonthValueField ? data.transferPeriodSettleMonthValueField : moment(new Date());
    const _decadeSymbol = data.transferPeriodSettleDecadeValueField ? data.transferPeriodSettleDecadeValueField.symbol : "";
    const _dayDate = data.transferSettleDayField ? data.transferSettleDayField : moment(new Date());

    switch (periodType) {
    case "R":
      periodNum = moment(_yearDate).format("YY") + periodType;
      break;
    case "P":
      periodNum = moment(_yearDate).format("YY") + periodType + _halfyearSymbol;
      break;
    case "K":
      periodNum = moment(_yearDate).format("YY") + periodType + _quarterSymbol;
      break;
    case "M":
      periodNum = moment(_monthDate).format("YY") + periodType + moment(_monthDate).format("MM");
      break;
    case "D":
      periodNum = moment(_monthDate).format("YY") + periodType + _decadeSymbol + moment(_monthDate).format("MM");
      break;
    case "J":
      periodNum = moment(_dayDate).format("YY") + periodType + moment(_dayDate).format("DD") + moment(_dayDate).format("MM");
      break;
    case "0":
      periodNum = "0";
      break;
    default:
      periodNum = null;
      break;
    }
    return periodNum;
  }
}